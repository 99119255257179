import './RegisterUser.css';
import Logo from '../../../../assets/images/cae-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Icon, TextInput } from '@cae/cobalt-react';
import { Controller, useForm } from 'react-hook-form';
import handleHelpButtonClick from '@/shared/helpers/help-button/helpButtonClick';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import EmailExists from '../../../../../public/img/email-exists.svg';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { CheckinUserRequest } from '@/open-api';
import axios, { AxiosError, AxiosResponse } from 'axios';
import handleRequestError from '@/shared/helpers/errorHandling';
import APIConfig from '@/configs/urlConfig';
import { loginRequest } from '@/configs/authConfig';
import Logger from '@/libs/logging/Logger';
import { useMsal } from '@azure/msal-react';
import { useUserInfo } from '@/contexts/userInfo';
import { acquireAccessToken } from '@/shared/helpers/getAccessToken';

interface ErrorResponse {
  detail: string;
}

export type CreateNewUserFormData = z.infer<typeof createUserSchema>;

const createUserSchema = z.object({
  firstName: z.string().min(3).max(255).min(1, 'First name is required'),
  middleName: z.string().max(255),
  lastName: z.string().min(2).max(255).min(1, 'Last name is required'),
  email: z.string().email().min(1, 'Email is required'),
  emailOptIn: z.boolean(),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  middleName: '',
  email: '',
  emailOptIn: false,
};
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_WEBAPI_URI,
  headers: {
    'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
  },
});

export const registerUser = async (
  data: any // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse | AxiosError> => {
  try {
    const response = await axiosInstance.post('/users/enroll_public_visitors', {
      ...data,
    });
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};

export function RegisterUser(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const { firstName, middleName, lastName, email, isVisitorExists } =
    useUserInfo();

  useEffect(() => {
    const activeAccount = instance.getActiveAccount();

    if (isVisitorExists === undefined && activeAccount) {
      setLoading(true);
    }
  }, [isVisitorExists, instance]);

  useEffect(() => {
    if (loading && isVisitorExists !== undefined) {
      const handleVisitorStatus = async (): Promise<void> => {
        if (isVisitorExists) {
          navigate('/home');
        } else {
          try {
            const response = await axiosInstance.post(
              '/users/enroll_public_visitors',
              {
                firstName,
                middleName,
                lastName,
                email,
              }
            );

            if (response.status === 201) {
              navigate('/home');
            } else {
              toastMessages.error(
                'Error in assigning visitor role. Please try again.'
              );
              navigate('/home');
            }
          } catch (error) {
            handleRequestError(error);
            toastMessages.error('An error occurred. Please contact support.');
          } finally {
            setLoading(false);
          }
        }
      };

      handleVisitorStatus();
    }
  }, [
    loading,
    isVisitorExists,
    navigate,
    firstName,
    middleName,
    lastName,
    email,
    instance,
  ]);

  const handleLogin = async (): Promise<void> => {
    setLoading(true);
    const token = await acquireAccessToken();
    if (token && !isUserLoggedIn) {
      setIsUserLoggedIn(true);
      await instance.logoutPopup();
      setIsUserLoggedIn(false);
    }

    try {
      const redirectStartPage = '/home';
      await instance.loginRedirect({ ...loginRequest, redirectStartPage });
    } catch (error) {
      if (error instanceof Error) {
        Logger.log({
          type: 'code',
          level: 'error',
          message: `UseLogin thrown an exception: ${error.message}`,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
  } = useForm<CreateNewUserFormData>({
    mode: 'onChange',
    resolver: zodResolver(createUserSchema),
    defaultValues,
  });

  const onSubmit = async (data: CheckinUserRequest): Promise<void> => {
    setLoading(true);
    sessionStorage.setItem('loginHint', data.email);
    try {
      const res = await registerUser(data);
      if (res instanceof Error) {
        if (res.response && res.response.status === 500) {
          const errorData = res.response.data as ErrorResponse;
          toastMessages.error(errorData.detail);
        } else if (res.response && res.response.status === 409) {
          setEmailExists(true);
        }
      } else if (res.status === 201) {
        const redirectStartPage = '/home';
        instance.loginRedirect({ ...loginRequest, redirectStartPage });
      }
    } catch {
      toastMessages.error('Please Contact Support');
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = (): void => {
    setEmailExists(false);
  };

  const renderContent = (): JSX.Element => {
    if (loading) {
      return <div className="loader-circular" data-testid="loader"></div>;
    }

    if (emailExists) {
      return (
        <div className="email-exists">
          <img src={EmailExists} alt="cae-logo" />
          <p>
            It looks like an account with this email has <br /> already been
            registered.
          </p>
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={() => handleLogin()}
            title="Sign in button"
          >
            Login here
          </Button>
        </div>
      );
    }

    return (
      <form className="user-form" onSubmit={handleSubmit(onSubmit)}>
        <p>
          Already have one?{' '}
          <Link className="signin" onClick={() => handleLogin()} to={''}>
            Login here
          </Link>
        </p>
        <div className="divider-container">
          <div className="divider" />
          <div className="or-text">or</div>
          <div className="divider" />
        </div>
        <div className="user-form-fields">
          <Controller
            name="firstName"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <TextInput
                label="First name"
                field={{ ...field, placeholder: 'Type first name' }}
                meta={{
                  dirty: isDirty,
                  error: error?.message ?? '',
                  touched: isTouched,
                  valid: !invalid,
                }}
              />
            )}
          />
          <Controller
            name="middleName"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <TextInput
                label="Middle name (Optional)"
                field={{ ...field, placeholder: 'Type middle name' }}
                meta={{
                  dirty: isDirty,
                  error: error?.message ?? '',
                  touched: isTouched,
                  valid: !invalid,
                }}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <TextInput
                label="Last name"
                field={{ ...field, placeholder: 'Type last name' }}
                meta={{
                  dirty: isDirty,
                  error: error?.message ?? '',
                  touched: isTouched,
                  valid: !invalid,
                }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <TextInput
                label="Email"
                field={{
                  ...field,
                  placeholder: 'Type email',
                  disabled: !!defaultValues.email,
                }}
                meta={{
                  dirty: isDirty,
                  error: error?.message ?? '',
                  touched: isTouched,
                  valid: !invalid,
                }}
              />
            )}
          />
          <Controller
            name="emailOptIn"
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
            }) => (
              <Checkbox
                field={{
                  id: 'create-new-user-email-checkbox',
                  checked: field.value,
                  onChange: e => field.onChange(e.target.checked),
                }}
                meta={{
                  dirty: isDirty,
                  error: error?.message ?? '',
                  touched: isTouched,
                  valid: !invalid,
                }}
                label={
                  <span>
                    (Optional) I would like to receive email communications
                    <br />
                    and new product updates from CAE.
                  </span>
                }
                className="new-user-checkbox"
              />
            )}
          />
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting || !isDirty || !isValid}
            title="Create a visitor role"
          >
            Next
          </Button>
        </div>
      </form>
    );
  };

  return (
    <div className="create-new-user">
      <div className="cae-header">
        <img className="cae-header--logo" src={Logo} alt="cae-logo" />
        <div className="cae-header--header">
          <h3 className="cae-header--cae">Create a new CAE Connect</h3>
          <span>Enhance your experience with CAE.</span>
        </div>
      </div>

      <Button
        className="help-button"
        variant="transparent"
        onClick={handleHelpButtonClick}
      >
        <Icon id="help-circle-outline" size="sm" />
      </Button>

      {emailExists && (
        <Button
          variant="transparent"
          className="navigate-back"
          onClick={handleGoBack}
        >
          <Icon id="arrow-left-solid" size="xs" />
          <p>Back</p>
        </Button>
      )}

      {renderContent()}
    </div>
  );
}
