import { useReducer } from 'react';
import { DefinedReservationFilters } from '@/features/reservations/components/filter-modal/useFilterModalReducer';
import { Actions, filtersReducer } from './reservationFilterReducer';

const initialState: DefinedReservationFilters = {
  location: [],
  platform: [],
  courseType: [],
  etcaStatus: [],
  tsaStatus: [],
  scheduleStatus: [],
  trainingRecord: [],
  dateRange: [],
  authorities: [],
};

function useReservationFilter(): {
  filters: DefinedReservationFilters;
  resetFilters: () => void;
  toggleAllFilters: (selected: DefinedReservationFilters) => void;
  filterCounter: () => number;
} {
  const [filters, dispatch] = useReducer(filtersReducer, initialState);
  const toggleAllFilters = (selected: DefinedReservationFilters): void => {
    dispatch({ type: Actions.toggleAllFilters, payload: selected });
  };

  const resetFilters = (): void => {
    dispatch({ type: Actions.resetFilter });
  };

  const filterCounter = (): number => {
    let count = 0;

    count = [
      ...filters.location,
      ...filters.platform,
      ...filters.courseType,
      ...filters.etcaStatus,
      ...filters.tsaStatus,
      ...filters.scheduleStatus,
      ...filters.trainingRecord,
      ...filters.authorities,
    ].length;

    if (filters.dateRange && filters.dateRange.length > 0) {
      count += 1;
    }

    return count;
  };

  return {
    filters,
    resetFilters,
    toggleAllFilters,
    filterCounter,
  };
}

export default useReservationFilter;
