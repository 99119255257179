import ETCA from '@/features/etca-mfe/ETCA';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';

const ETCAMfeRoute: React.FC = () => {
  const { eTcaEnabledWebApp } = useFlags();
  return eTcaEnabledWebApp ? <ETCA /> : <Navigate to="/home" replace />;
};

export const etcaMfeRoutes = {
  path: 'eTCA',
  element: <ETCAMfeRoute />,
};
