import { Tabs } from '@cae/cobalt-react';
import { t } from 'i18next';

import { DefinedReservationFilters } from '@/features/reservations/components/filter-modal/useFilterModalReducer';
import { ReservationsListing } from '../reservations-listing/ReservationsListing';

import type { ReservationType } from '../../api/useGetReservationsTableDataQuery';
import { useTotalItems } from '@/contexts/TotalItemsContext';

type ReservationTab = {
  key: ReservationType;
  type: string;
};

const RESERVATIONS_TABS: ReservationTab[] = [
  { key: 'all', type: 'all' },
  { key: 'upcoming', type: 'upcoming' },
  { key: 'inProgress', type: 'in-progress' },
  { key: 'recentlyCompleted', type: 'recently' },
];

interface ReservationsTabsProps {
  filters: DefinedReservationFilters;
  searchText?: string;
}

export function ReservationsTabs({
  filters,
  searchText = '',
}: ReservationsTabsProps): JSX.Element {
  const { totalItems } = useTotalItems();

  return (
    <Tabs variant="contained" defaultIndex={0}>
      {({ selectedIndex = 1 }) => (
        <>
          <Tabs.TabList>
            {RESERVATIONS_TABS.map((tab, i) => (
              <Tabs.Tab
                id={`tab--reservations-${tab.type}`}
                key={`tab--reservations-${tab.type}`}
                data-testid={`${tab.key}ReservationTabs`}
                selected={i === selectedIndex}
              >
                {t(`Reservations.${tab.key}`)}
                <i className="count">({totalItems[tab.key] ?? 0})</i>
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
          {RESERVATIONS_TABS.map((panel, i) => (
            <Tabs.TabPanel
              id={`tab-panel--reservations-${panel.type}`}
              selected={i === selectedIndex}
              aria-labelledby={`tab--reservations-${panel.type}`}
              key={panel.type}
              data-testid={`${panel.key}ReservationsList`}
            >
              <ReservationsListing
                reservationsType={panel.key}
                filters={filters}
                searchText={searchText}
              />
            </Tabs.TabPanel>
          ))}
        </>
      )}
    </Tabs>
  );
}

export default ReservationsTabs;
