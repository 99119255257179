/* eslint import/no-unresolved: "off" */
import { useUserInfo } from '@/contexts/userInfo';
import { useMsal } from '@azure/msal-react';
import { ETCAProps } from 'etca_mfe/ETCA';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './ETCA.css';

function ETCA(): JSX.Element {
  const [RemoteComponent, setRemoteComponent] =
    React.useState<React.FC<ETCAProps> | null>(null);
  const [loadError, setLoadError] = React.useState<Error | null>(null);

  const navigate = useNavigate();
  const { eTcaEnabledWebApp } = useFlags();

  React.useEffect(() => {
    if (!eTcaEnabledWebApp) {
      navigate('/home');
    }
  }, [eTcaEnabledWebApp, navigate]);

  React.useEffect(() => {
    const loadComponent = async (): Promise<void> => {
      try {
        const { default: Component } = await import('etca_mfe/ETCA');
        setRemoteComponent(() => Component);
      } catch (error) {
        console.error('Error loading remote component:', error);
        setLoadError(error as Error);
      }
    };

    loadComponent();
  }, []);

  const { instance } = useMsal();
  const userInfo = useUserInfo();

  return (
    <div className="etca-host">
      {loadError ? (
        <div>
          Error loading the micro-frontend component. Please try again later.
        </div>
      ) : (
        <React.Suspense fallback={<div>Loading...</div>}>
          {RemoteComponent ? (
            <RemoteComponent msalInstance={instance} user={userInfo} />
          ) : (
            <div>Loading...</div>
          )}
        </React.Suspense>
      )}
    </div>
  );
}

export default ETCA;
