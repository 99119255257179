import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ReservationFilters, ReservationManagementService } from '@/open-api';

export function useReservationFiltersQuery(
  organizationId?: string
): UseQueryResult<ReservationFilters, Error> {
  return useQuery<ReservationFilters, Error>({
    queryKey: ['reservationFilters', organizationId],
    queryFn: () =>
      ReservationManagementService.getReservationFilters({ organizationId }),
  });
}
