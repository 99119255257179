import './InvoicePaymentsInfoFragments.css';
import { InvoiceDetailsResponse } from '@/open-api';
import CurrencyFormat from '@/shared/components/format-currency/FormatCurrency';
import { selectInvoiceFragments } from '../../api/selectors';
type Props = {
  readonly invoiceDetails: InvoiceDetailsResponse;
};

export function InvoicePaymentsInfoFragments({
  invoiceDetails,
}: Props): JSX.Element {
  const invoiceFragments = selectInvoiceFragments(invoiceDetails);

  const getAmount = (): JSX.Element | string => {
    const currencyValue = invoiceFragments?.currency ?? '';
    if (invoiceFragments.amount === '-') return invoiceFragments.amount;
    const currencyFormatComponent = (
      <CurrencyFormat
        value={invoiceFragments?.amount as number}
        currency={currencyValue}
      />
    );
    if (invoiceFragments.type === 'CreditMemo') {
      return (
        <span style={{ color: 'green' }}>({currencyFormatComponent})</span>
      );
    }
    return <span>{currencyFormatComponent}</span>;
  };

  return (
    <div>
      {invoiceDetails && (
        <section className="dialog-details__fragment quick-info">
          <ul>
            <li>
              <h6>Due date</h6>
              <p>{invoiceFragments.dueDate}</p>
            </li>
            <li>
              <h6>Issued on</h6>
              <p>{invoiceFragments.issuedOn}</p>
            </li>
            <li>
              <h6>Amount due</h6>
              <p>{getAmount()}</p>
            </li>
            <li>
              <h6>Legal entity</h6>
              <p>{invoiceFragments.legalEntityName}</p>
            </li>
            <li>
              <h6>PO number</h6>
              <p>{invoiceFragments.poNumbers}</p>
            </li>
            <li>
              <h6>Contract agreement</h6>
              <p>{invoiceFragments.contractNumber}</p>
            </li>
          </ul>
        </section>
      )}
    </div>
  );
}
