import { Navigate, createBrowserRouter } from 'react-router-dom';

import { documentsRoutes } from '@/routes/documentsRoutes';
import { reservationsRoutes } from '@/routes/reservationsRoutes';

import { instructorManagementRoutes } from '@/routes/instructorManagementRoutes';
import { LandingPage } from './pages/landing';
import PrivateRoute from './shared/components/private-route/PrivateRoute';
import OrgSwitchWrapper from './shared/components/org-switche-wrapper/OrgSwitchWrapper';
import { RecordsRoutes } from './routes/recordsRoutes';
import RolesPage from './pages/roles/RolesPage';
import { usersRoutes } from './routes/usersRoutes';
import SelectCustomerPage from './pages/select-customers/SelectCustomerPage';
import { CookiePolicyPage } from './pages/cookie-policy/CookiePolicyPage';
import { profileRoutes } from './routes/profileRoutes';
import { homeRoutes } from './routes/homeRoutes';
import { companyProfileRoutes } from './routes/companyProfileRoutes';
import { invoiceAndPaymentsRoutes } from './routes/invoiceAndPaymentsRoutes';
import OrganizationSelectorPage from './pages/organization-selector/OrganizationSelectorPage';
import { sfRoutes } from './routes/sfRoutes';
import { RegisterUser } from './features/users/components/register-user/RegisterUser';
import { scheduleMfeRoutes } from './routes/scheduleMfeRoutes';
import { accesspassMfeRoutes } from './routes/accesspassMfeRoutes';
import { etcaMfeRoutes } from './routes/eTCAMfeRoutes';
import { instructorPlatformRoutes } from '@/routes/instructorPlatformRoutes.tsx';
import { instructorsRoutes } from './routes/instructorsRoutes';
import UserForbidden from './features/users/components/user-forbidden/UserForbidden';

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      { index: true, element: <LandingPage /> },
      { path: 'sso', element: <LandingPage /> },
      { path: 'cookie-policy', element: <CookiePolicyPage /> },
      { path: 'register-user', element: <RegisterUser /> },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      { path: 'select-customer', element: <SelectCustomerPage /> },
      { ...usersRoutes },
      { ...instructorManagementRoutes },
      { ...instructorPlatformRoutes },
      { ...instructorsRoutes },
      { ...RecordsRoutes },
      { ...documentsRoutes },
      { ...reservationsRoutes },
      { ...invoiceAndPaymentsRoutes },
      { ...profileRoutes },
      { ...companyProfileRoutes },
      { ...scheduleMfeRoutes },
      { ...accesspassMfeRoutes },
      { ...etcaMfeRoutes },
      { path: 'org-switcher', element: <OrgSwitchWrapper /> },
      { path: 'roles', element: <RolesPage /> },
      {
        ...homeRoutes,
      },
      { path: '*', element: <Navigate to="/home" /> },
    ],
  },
  { path: 'no-access-user', element: <UserForbidden /> },
  { ...sfRoutes },
  { path: 'org-selector', element: <OrganizationSelectorPage /> },
]);

export default router;
