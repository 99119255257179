import * as React from 'react';
import './AlertDashboardCarousel.css';
import { Card, Icon } from '@cae/cobalt-react';
import { useGetAlertsDataQuery } from '../../api/useGetAlertsDataQuery';
import { Link } from 'react-router-dom';
import {
  InvoicesDue,
  RecordAlertsResponse,
  ReservationResponse,
} from '@/open-api';
import MultipleItemsCarousel from '../multi-slider-carousel/MultipleAlertCarosel';

const alertTypes = [
  {
    key: 'invoicesPastDueCount',
    title: 'Past due invoice',
    type: 'invoicesPastDueList',
  },
  {
    key: 'ectaResponse',
    title: 'Missing eTCA in upcoming reservations',
    type: 'ectaResponse',
  },
  {
    key: 'provisionalResponse',
    title: 'Provisional reservations at risk of cancellation',
    type: 'provisionalResponse',
  },
  {
    key: 'recordAlertsResponse',
    title: 'New digital records published in the last 14 days',
    type: 'recordAlertsResponse',
  },
];
const AlertCard: React.FC<{
  count: number;
  title: string;
}> = ({ count, title }) => {
  return (
    <div>
      <Card className="carosel-card">
        <h3>{count}</h3>
        <p>{title}</p>
      </Card>
    </div>
  );
};

const AlertDashboardCarousel: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { data, error, isLoading } = useGetAlertsDataQuery(organizationId);

  if (isLoading) return <p>Loading alerts...</p>;
  if (error) return <div className="error">Error loading alerts data.</div>;

  const getAlertCount = (alertTypeKey: string): number => {
    switch (alertTypeKey) {
      case 'invoicesPastDueCount':
        return data.invoicesPastDueCount ?? 0;
      case 'ectaResponse':
        return data.ectaResponse?.length ?? 0;
      case 'provisionalResponse':
        return Array.isArray(data.provisionalResponse)
          ? data.provisionalResponse.length
          : 0;
      case 'recordAlertsResponse':
        return data.recordAlertsResponse?.availableRecordsCount ?? 0;
      default:
        return 0;
    }
  };

  const getAlertData = (
    alertTypeKey: string
  ):
    | InvoicesDue[]
    | ReservationResponse[]
    | RecordAlertsResponse
    | undefined => {
    switch (alertTypeKey) {
      case 'invoicesPastDueCount':
        return data.invoicesPastDueList;
      case 'ectaResponse':
        return data.ectaResponse;
      case 'provisionalResponse':
        return data.provisionalResponse;
      case 'recordAlertsResponse':
        return data.recordAlertsResponse?.availableRecordsList;
      default:
        return [];
    }
  };

  const totalCount = alertTypes.reduce(
    (sum, alertType) => sum + getAlertCount(alertType.key),
    0
  );

  if (totalCount === 0)
    return (
      <Card
        elevation="skim"
        className="no-alerts"
        style={{ gridColumn: '1/6' }}
      >
        <Icon
          className="status-icon success"
          id="checkmark-circle-solid"
          size="md"
        />
        <h3>You have no alerts</h3>
        <p>Looks like everything is taken care of and where it should be.</p>
      </Card>
    );

  return (
    <>
      <p className="title-alerts">Your alerts</p>
      <MultipleItemsCarousel>
        {alertTypes.map(alertType => {
          const alertCount = getAlertCount(alertType.key);
          const alertData = getAlertData(alertType.key);

          return (
            alertCount > 0 && (
              <Link
                to={`/home/alerts/${alertType.title}/${alertType.type}`}
                state={{ alertData }}
                key={alertType.key}
              >
                <AlertCard count={alertCount} title={alertType.title} />
              </Link>
            )
          );
        })}
      </MultipleItemsCarousel>
    </>
  );
};

export default AlertDashboardCarousel;
