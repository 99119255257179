import * as React from 'react';
import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { Button, Dialog, Modal } from '@cae/cobalt-react';

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}
export function RecordDetailsPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
  const handleCloseRecord = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (window.location.href.includes('/request')) {
      if (hasUnsavedChanges) {
        setIsDiscardDialogOpen(true);
      } else {
        navigate(`/records/${recordId}`);
      }
    } else {
      navigate('/records');
    }
  };

  const handleDiscardConfirm = (): void => {
    setIsDiscardDialogOpen(false);
    navigate(`/records/${recordId}`);
  };

  const handleDiscardCancel = (): void => {
    setIsDiscardDialogOpen(false);
  };

  return recordId ? (
    <>
      <Modal
        animate
        dismissible
        onCancel={handleCloseRecord}
        open
        placement="right"
        className="record-details--dialog"
      >
        <Outlet context={{ handleCloseRecord, setHasUnsavedChanges }} />
      </Modal>
      <Dialog
        modal
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard changes?</h2>}
        onCancel={handleDiscardCancel}
        onConfirm={handleDiscardConfirm}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </>
  ) : null;
}
