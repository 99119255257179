import { Button } from '@cae/cobalt-react';
import { ReservationFilters } from '@/open-api';
import { FilterSection, FilterDateRangePicker } from '@/shared/components';

import {
  DefinedReservationFilters,
  useFilterModalReducer,
} from './useFilterModalReducer';

export type FilterModalProps = {
  filterOptions: ReservationFilters | undefined;
  filters: DefinedReservationFilters;
  resetFilters: () => void;
  toggleFilterSection: () => void;
  toggleAllFilters: (selected: DefinedReservationFilters) => void;
};

function compareArrays(
  filters: string[] | undefined,
  selectedValues: string[]
): boolean {
  if (!filters && selectedValues.length === 0) return true;
  if (!filters || !selectedValues) return false;
  if (filters.length !== selectedValues.length) return false;
  return filters.every(item => selectedValues.includes(item));
}

export function FilterModal({
  filterOptions,
  filters,
  toggleAllFilters,
  resetFilters,
  toggleFilterSection,
}: FilterModalProps): JSX.Element {
  const {
    selectedFilters,
    clearSelectedFilters,
    changeSelectedLocations,
    changeSelectedPlatforms,
    changeSelectedCourseTypes,
    changeSelectedEtcaStatus,
    changeSelectedTsaStatus,
    changeSelectedScheduledStatus,
    changeSelectedRecords,
    changeDateRange,
    changeSelectedAuthorities,
  } = useFilterModalReducer(filters);

  const handleApply = (): void => {
    toggleAllFilters(selectedFilters);
    toggleFilterSection();
  };

  const handleReset = (): void => {
    resetFilters();
    clearSelectedFilters();
  };

  return (
    <div className="filter-list">
      <h3>Filters</h3>
      <div className="filter-sections">
        <FilterDateRangePicker
          onSelect={changeDateRange}
          selected={selectedFilters.dateRange}
          title="Start date range"
        />
        <FilterSection
          label="Authorities"
          options={filterOptions?.authorities}
          selected={selectedFilters.authorities}
          onSelect={changeSelectedAuthorities}
          showSearch={false}
        />
        <FilterSection
          label="Course type"
          options={filterOptions?.courseType}
          selected={selectedFilters.courseType}
          onSelect={changeSelectedCourseTypes}
          showSearch={false}
        />
        <FilterSection
          label="eTCA status"
          options={filterOptions?.etcaStatus}
          selected={selectedFilters.etcaStatus}
          onSelect={changeSelectedEtcaStatus}
          showSearch={false}
        />
        <FilterSection
          label="Locations"
          searchPlaceholder="Search Locations"
          options={filterOptions?.location}
          selected={selectedFilters.location}
          onSelect={changeSelectedLocations}
        />
        <FilterSection
          label="Platforms"
          searchPlaceholder="Search Platforms"
          options={filterOptions?.platform}
          selected={selectedFilters.platform}
          onSelect={changeSelectedPlatforms}
        />
        <FilterSection
          label="Records"
          options={filterOptions?.trainingRecord}
          selected={selectedFilters.trainingRecord}
          onSelect={changeSelectedRecords}
          showSearch={false}
        />
        <FilterSection
          label="Schedule status"
          options={filterOptions?.scheduleStatus}
          selected={selectedFilters.scheduleStatus}
          onSelect={changeSelectedScheduledStatus}
          showSearch={false}
        />
        <FilterSection
          className="last"
          label="TSA status"
          options={filterOptions?.tsaStatus}
          selected={selectedFilters.tsaStatus}
          onSelect={changeSelectedTsaStatus}
          showSearch={false}
        />
      </div>
      <div className="actions">
        <Button variant="ghost" onClick={handleReset}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={handleApply}
          disabled={
            compareArrays(filters.location, selectedFilters.location) &&
            compareArrays(filters.platform, selectedFilters.platform) &&
            compareArrays(filters.courseType, selectedFilters.courseType) &&
            compareArrays(filters.etcaStatus, selectedFilters.etcaStatus) &&
            compareArrays(filters.tsaStatus, selectedFilters.tsaStatus) &&
            compareArrays(
              filters.scheduleStatus,
              selectedFilters.scheduleStatus
            ) &&
            compareArrays(
              filters.trainingRecord,
              selectedFilters.trainingRecord
            ) &&
            compareArrays(
              filters.dateRange.map(date => (date ? date.toDateString() : '')),
              selectedFilters.dateRange.map(date =>
                date ? date.toDateString() : ''
              )
            ) &&
            compareArrays(filters.authorities, selectedFilters.authorities)
          }
        >
          Apply
        </Button>
      </div>
    </div>
  );
}

export default FilterModal;
