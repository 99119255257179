import { Icon } from '@cae/cobalt-react';
import './LandingNeedHelp.css';
import { useTranslation } from 'react-i18next';
import handleHelpButtonClick from '@/shared/helpers/help-button/helpButtonClick';

function LandingNeedHelp(): JSX.Element {
  const { t, i18n } = useTranslation();

  const language = i18n.language || 'en';

  return (
    <div className="landing-need-help">
      <div className="landing-need-help-swirls right"></div>
      <div className="landing-need-help-swirls left"></div>
      <div className="landing-need-help-top">
        <h2>{t('common.needHelp')}</h2>
        <p>{t('landingPage.helpDescription')}</p>
      </div>
      <div className="landing-need-help-content">
        <button
          className="landing-need-help-content-card"
          onClick={handleHelpButtonClick}
        >
          <h3>{t('common.callUs')}</h3>
          <Icon id="phone-outline" size="xxl"></Icon>
        </button>
        <button
          className="landing-need-help-content-card"
          onClick={handleHelpButtonClick}
        >
          <h3>{t('common.messageUs')}</h3>
          <Icon id="email-outline" size="xxl"></Icon>
        </button>
        <button
          className="landing-need-help-content-card"
          onClick={handleHelpButtonClick}
        >
          <h3>{t('common.chatWithUs')}</h3>
          <Icon id="comment-outline" size="xxl"></Icon>
        </button>
      </div>
      <div className="landing-need-help-footer">
        <span>©{t('landingPage.caeInc')}</span>
        <span className="landing-need-help-footer-links">
          <a href={`${window.location.origin}/sso`} rel="noreferrer">
            {t('landingPage.employeeLogin')}
          </a>{' '}
          |
          <a
            href={
              language === 'fr'
                ? 'https://www.cae.com/fr/modalites-dutilisation/'
                : 'https://www.cae.com/terms-of-use-agreement/'
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('landingPage.legalNotice')}
          </a>{' '}
          |
          <a
            href={
              language === 'fr'
                ? 'https://www.cae.com/fr/politique-de-protection-de-la-vie-privee/'
                : 'https://www.cae.com/privacy-policy/'
            }
            target="_blank"
            rel="noreferrer"
          >
            {t('landingPage.caePrivacy')}
          </a>{' '}
          |
          <a href={'/cookie-policy/'} target="_blank" rel="noreferrer">
            {t('landingPage.cookiePolicy')}
          </a>{' '}
          |
          <button
            type="button"
            rel="noreferrer"
            className="ot-sdk-show-settings"
          >
            {t('landingPage.changeCookieSettings')}
          </button>
        </span>
      </div>
    </div>
  );
}

export default LandingNeedHelp;
