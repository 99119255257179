import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { z } from 'zod';
import APIConfig from '../../../configs/urlConfig';
import handleRequestError from '@/shared/helpers/errorHandling';
import { acquireAccessToken } from '@/shared/helpers/getAccessToken';

interface UserEditResponse {
  active: boolean;
  roles: string[];
}

const createUserSchema = z.object({
  firstName: z
    .string()
    .min(6, 'First name must be at least 6 characters long')
    .max(32),
  middleName: z.string().max(32),
  lastName: z
    .string()
    .min(6, 'Last name must be at least 6 characters long')
    .max(32),
  email: z.string().email('Invalid email address').min(1, 'Email is required'),
  active: z.boolean().default(true),
  roles: z
    .array(
      z
        .string()
        .refine(
          value =>
            ['administrator', 'finance', 'training', 'records'].includes(value),
          { message: 'You must select at least one role' }
        )
    )
    .min(1, 'Roles is required'),
});

type CreateUserFormData = z.infer<typeof createUserSchema>;

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_WEBAPI_URI,
  headers: {
    'Ocp-Apim-Subscription-Key': APIConfig.subscriptionKey,
  },
});

export const createExternalUser = async (
  data: CreateUserFormData,
  organizationId: string | undefined
): Promise<AxiosResponse | AxiosError> => {
  try {
    const token = await acquireAccessToken();

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Organization-Id': organizationId,
      },
    };

    const response = await axiosInstance.post(
      '/users',
      { ...data, username: data.email, selectedOrganization: organizationId },
      config
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};

export const updateUser = async (
  userId: string,
  userDetails: UserEditResponse,
  organizationId: string | undefined
): Promise<AxiosResponse | AxiosError> => {
  try {
    const token = await acquireAccessToken();

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Organization-Id': organizationId,
      },
    };

    const response = await axiosInstance.put(
      `/users/${userId}`,
      { ...userDetails, selectedOrganization: organizationId },
      config
    );
    return response;
  } catch (error) {
    return handleRequestError(error);
  }
};
