import { loginRequest } from '@/configs/authConfig';
import { msalInstance } from '@/shared/hooks/msalInstance';
import { jwtDecode } from 'jwt-decode';

interface TokenPayload {
  exp: number;
  email: string;
}

const isTokenExpired = (token: string): boolean => {
  const { exp } = jwtDecode<TokenPayload>(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= exp;
};

const getEmailFromToken = (token: string): string => {
  const { email } = jwtDecode<TokenPayload>(token);
  return email;
};

export const acquireAccessToken = async (): Promise<string> => {
  const { msal } = await msalInstance();

  if (!msal.getActiveAccount()) {
    throw new Error('No active account found');
  }

  const account = msal.getActiveAccount();
  if (!account) {
    throw new Error('No active account found');
  }

  const existingToken = localStorage.getItem('id_token');
  const userEmail = account.idTokenClaims?.email;

  if (existingToken && !isTokenExpired(existingToken)) {
    const tokenEmail = getEmailFromToken(existingToken);
    if (tokenEmail === userEmail) {
      return existingToken;
    }
  }

  const request = {
    account,
    ...loginRequest,
  };

  try {
    const { idToken } = await msal.acquireTokenSilent(request);
    localStorage.setItem('id_token', idToken);
    return idToken;
  } catch (error) {
    console.error(
      'Silent token acquisition failed, falling back to interactive',
      error
    );

    const response = await msal.acquireTokenPopup(request);
    localStorage.setItem('id_token', response.accessToken);
    return response.accessToken;
  }
};
