import './CookiePolicyPage.css';
import { useEffect } from 'react';

interface ScriptAttributes {
  [key: string]: string;
}

const useScript = (url: string, attributes: ScriptAttributes = {}): void => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    // Apply additional attributes to the script element
    Object.keys(attributes).forEach(key => {
      script.setAttribute(key, attributes[key]);
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, attributes]);
};

export function CookiePolicyPage(): JSX.Element {
  useScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', {
    'data-domain-script':
      import.meta.env.VITE_REACT_APP_DATA_DOMAIN_SCRIPT ?? '',
  });

  return (
    <div className="cookie-policy-container">
      <head>
        <script type="text/javascript">function OptanonWrapper() {}</script>

        <meta charSet="UTF-8" />
        <title>CAE Connect Analytics - Cookie Policy</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Red+Hat+Regular:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="{{ url_for('assets', filename='favicon.ico') }}"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="assets/onetrust_cookie_policy.css"
        />
      </head>

      <body>
        <div className="policy-header">
          <h1>Cookie Policy</h1>
          <h3>Introduction</h3>
          <p>
            This policy covers usage of cookies and other tracking technologies
            on CAE’s websites. When you visit any CAE’s website, it may store or
            retrieve information on your browser, mostly in the form of cookies.
            This information might be about you, your preferences or your device
            and is mostly used to make the site work as you expect it to. The
            information does not usually directly identify you, but it can give
            you a more personalized web experience. Because we respect your
            right to privacy, you can choose not to allow some types of cookies.
            Below is a detailed list of the cookies we use on our website. Our
            website is scanned with our cookie scanning tool regularly to
            maintain a list as accurate as possible. Click on the “Change Cookie
            Settings” link and subsequently on the different category headings
            to find out more and change our default settings. However, blocking
            some types of cookies may impact your experience of the site and the
            services we are able to offer.
          </p>
          <p>
            By clicking “Accept Recommended Settings”, you agree to the usage of
            tracking technologies and the storing of cookies on your device for
            such purposes.
          </p>
        </div>

        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
        <br />
        <br />
        <br />

        <div id="ot-sdk-cookie-policy" />

        <div className="policy-footer">
          <h4>Cookie policy does not cover third-party websites/application</h4>
          <p>
            CAE’s websites may contain links to the sites of third parties or
            parts of their sites. However, CAE has no control over the content
            or operation of these sites, nor does it control the confidentiality
            or privacy practices of the site operators. It is therefore your
            responsibility to find out about their policies in order to protect
            information that concerns you.
          </p>
        </div>
      </body>
    </div>
  );
}
