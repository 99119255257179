import { DefinedReservationFilters } from '../components/filter-modal/useFilterModalReducer';

export const enum Actions {
  resetFilter = 'reset-filters',
  toggleAllFilters = 'toggle-fleets-bases-ranks',
}

type ResetFilterAction = {
  type: Actions.resetFilter;
};

type ToggleAllFiltersAction = {
  type: Actions.toggleAllFilters;
  payload: DefinedReservationFilters;
};

type ReservationFilterActions = ToggleAllFiltersAction | ResetFilterAction;

export function filtersReducer(
  state: DefinedReservationFilters,
  action: ReservationFilterActions
): DefinedReservationFilters {
  switch (action.type) {
    case Actions.toggleAllFilters:
      return {
        ...state,
        location: action.payload.location,
        platform: action.payload.platform,
        courseType: action.payload.courseType,
        etcaStatus: action.payload.etcaStatus,
        tsaStatus: action.payload.tsaStatus,
        scheduleStatus: action.payload.scheduleStatus,
        trainingRecord: action.payload.trainingRecord,
        dateRange: action.payload.dateRange,
        authorities: action.payload.authorities,
      };
    case Actions.resetFilter:
      return {
        location: [],
        platform: [],
        courseType: [],
        etcaStatus: [],
        tsaStatus: [],
        scheduleStatus: [],
        trainingRecord: [],
        dateRange: [],
        authorities: [],
      };
    default:
      return state;
  }
}
