import { DefinedReservationFilters } from '../filter-modal/useFilterModalReducer';
import { toastMessages } from '@/shared/helpers/toastMessages';
import { ExportReservationsButton } from '../export-reservations-button/ExportReservationsButton';
import { format } from 'date-fns';
import {
  CourseType,
  EtcaStatus,
  ScheduledStatus,
  TsaStatus,
} from '../../types';
import { useDownloadFile } from '@/shared/hooks/useDownloadFile';
import { Button, Modal } from '@cae/cobalt-react';
import { useState } from 'react';
import './ReservationsExport.css';

interface ReservationsExportProps {
  filters: DefinedReservationFilters;
  searchText?: string;
}

type FiltersQuery = {
  location?: string[];
  regulatoryAgencies?: string[];
  courseType?: CourseType[];
  platform?: string[];
  startDateRangeFrom?: string;
  startDateRangeTo?: string;
  etcaStatus?: EtcaStatus[];
  tsaStatus?: TsaStatus[];
  scheduleStatus?: ScheduledStatus[];
};
type ModalActionsProps = {
  handleCancel: React.MouseEventHandler;
  handleConfirm: React.MouseEventHandler;
};

function ModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Export
      </Button>
    </>
  );
}

function ReservationsExport({
  filters,
  searchText = '',
}: Readonly<ReservationsExportProps>): JSX.Element {
  const label = 'Export as .xlsx';
  const { downloadFile, status } = useDownloadFile({
    onSuccess: () => {
      toastMessages.success(`Export reservation successfully`);
    },
    onError: () => {
      toastMessages.error(`Export reservation failed`);
    },
  });

  const objectToQueryString = (obj: {
    [key: string]: string[] | number | string;
  }): string => {
    return Object.keys(obj)
      .filter(key => obj[key] !== undefined && obj[key] !== null)
      .map(key => {
        const value = obj[key];
        if (Array.isArray(value)) {
          const encodedValues = value.map(v => encodeURIComponent(v)).join(',');
          return `${encodeURIComponent(key)}=${encodedValues}`;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
  };

  const filtersQuery: FiltersQuery = {
    location: filters?.location,
    regulatoryAgencies: filters?.authorities,
    courseType: filters?.courseType,
    platform: filters?.platform,
    startDateRangeFrom: filters?.dateRange[0]
      ? format(filters.dateRange[0], 'yyyy-MM-dd')
      : undefined,
    startDateRangeTo: filters?.dateRange[1]
      ? format(filters.dateRange[1], 'yyyy-MM-dd')
      : undefined,
    etcaStatus: filters?.etcaStatus,
    tsaStatus: filters?.tsaStatus,
    scheduleStatus: filters?.scheduleStatus,
  };
  const filtersQueryString = objectToQueryString(filtersQuery);

  const url = `/reservations/export?status=all&search=${encodeURIComponent(searchText)}&${filtersQueryString}`;

  const handleClick = (): void => {
    setIsShowExportModal(false);
    downloadFile({
      url: url,
      fileName: `reservation.xlsx`,
    });
  };
  const [isShowExportModal, setIsShowExportModal] = useState(false);

  const handleCloseExportModal = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsShowExportModal(false);
  };
  const handleExportModal = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsShowExportModal(true);
  };

  return (
    <>
      <ExportReservationsButton
        onClick={handleExportModal}
        loading={status === 'pending'}
        label={label}
      />
      <Modal
        animate
        actions={ModalActions}
        dismissible
        onCancel={handleCloseExportModal}
        onConfirm={handleClick}
        open={isShowExportModal}
        placement="center"
        className="export--dialog"
        size="sm"
        header="Export table as .xlsx"
      >
        <p>
          You are downloading an .xlsx workbook including as data found in the
          table tabs
        </p>
      </Modal>
    </>
  );
}

export default ReservationsExport;
