/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingConfirmationEmailRequest } from '../models/BookingConfirmationEmailRequest';
import type { PagedListOfReservationResponseDto } from '../models/PagedListOfReservationResponseDto';
import type { RecordStatus } from '../models/RecordStatus';
import type { ReservationAlertsResponse } from '../models/ReservationAlertsResponse';
import type { ReservationChangeRequest } from '../models/ReservationChangeRequest';
import type { ReservationDetailsResponse } from '../models/ReservationDetailsResponse';
import type { ReservationFilters } from '../models/ReservationFilters';
import type { ReservationResponse } from '../models/ReservationResponse';
import type { ReservationsMetadata } from '../models/ReservationsMetadata';
import type { TrainingRecordDocument } from '../models/TrainingRecordDocument';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReservationManagementService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendBookingConfirmationEmail({
    reservationId,
    requestBody,
    organizationId,
  }: {
    reservationId: string;
    requestBody: BookingConfirmationEmailRequest;
    organizationId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reservations/{reservationId}/send-confirmation-email',
      path: {
        reservationId: reservationId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static sendReservationChangeRequest({
    reservationId,
    requestBody,
    organizationId,
  }: {
    reservationId: string;
    requestBody: ReservationChangeRequest;
    organizationId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/reservations/{reservationId}/change-request',
      path: {
        reservationId: reservationId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ReservationDetailsResponse OK
   * @throws ApiError
   */
  public static getReservationDetails({
    reservationId,
    organizationId,
  }: {
    reservationId: string;
    organizationId?: string;
  }): CancelablePromise<ReservationDetailsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/{reservationId}/details',
      path: {
        reservationId: reservationId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns TrainingRecordDocument OK
   * @throws ApiError
   */
  public static getReservationTrainingRecordDocument({
    reservationId,
    organizationId,
  }: {
    reservationId: string;
    organizationId?: string;
  }): CancelablePromise<TrainingRecordDocument> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/{reservationId}/details/record-document',
      path: {
        reservationId: reservationId,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns PagedListOfReservationResponseDto OK
   * @throws ApiError
   */
  public static getReservations({
    status,
    pageIndex,
    pageSize = 10,
    location,
    regulatoryAgencies,
    courseType,
    platform,
    startDateRangeFrom,
    startDateRangeTo,
    startDateAfter,
    endDateBefore,
    etcaStatus,
    tsaStatus,
    scheduleStatus,
    trainingRecordStatus,
    searchText,
    sort,
    organizationId,
  }: {
    /**
     * Status of the reservations
     */
    status: Array<
      | 'all'
      | 'provisional'
      | 'completed'
      | 'confirmed'
      | 'inProgress'
      | 'recentlyCompleted'
      | 'upcoming'
    >;
    /**
     * Page index
     */
    pageIndex?: number;
    /**
     * Page size
     */
    pageSize?: number;
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Regulatory Agencies Names
     */
    regulatoryAgencies?: Array<string>;
    /**
     * Course types
     */
    courseType?: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Date range start for start date of the training event
     */
    startDateRangeFrom?: string;
    /**
     * Date range end for start date of the training event
     */
    startDateRangeTo?: string;
    /**
     * Start date of the training event
     */
    startDateAfter?: string;
    /**
     * End date of the training event
     */
    endDateBefore?: string;
    /**
     * Etca status
     */
    etcaStatus?: Array<'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'>;
    /**
     * TSA status
     */
    tsaStatus?: Array<
      'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
    >;
    /**
     * Schedule status
     */
    scheduleStatus?: Array<'Available' | 'NotAvailable'>;
    /**
     * Training records status
     */
    trainingRecordStatus?: Array<RecordStatus>;
    /**
     * Search term for additional filtering on any of reservation number, pilot name and course name
     */
    searchText?: string;
    /**
     * Sort attributes
     */
    sort?: Array<
      | '+schedulerReservationId'
      | '+status'
      | '+clientName'
      | '+aircraftName'
      | '+courseName'
      | '+startDate'
      | '+endDate'
      | '+location'
      | '-schedulerReservationId'
      | '-status'
      | '-clientName'
      | '-aircraftName'
      | '-courseName'
      | '-startDate'
      | '-endDate'
      | '-location'
    >;
    organizationId?: string;
  }): CancelablePromise<PagedListOfReservationResponseDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/paged',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        status: status,
        location: location,
        regulatoryAgencies: regulatoryAgencies,
        courseType: courseType,
        platform: platform,
        startDateRangeFrom: startDateRangeFrom,
        startDateRangeTo: startDateRangeTo,
        startDateAfter: startDateAfter,
        endDateBefore: endDateBefore,
        etcaStatus: etcaStatus,
        tsaStatus: tsaStatus,
        scheduleStatus: scheduleStatus,
        trainingRecordStatus: trainingRecordStatus,
        searchText: searchText,
        sort: sort,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ReservationsMetadata OK
   * @throws ApiError
   */
  public static getReservationsMetadata({
    location,
    courseType,
    platform,
    regulatoryAgencies,
    startDateRangeFrom,
    startDateRangeTo,
    startDateAfter,
    endDateBefore,
    etcaStatus,
    tsaStatus,
    scheduleStatus,
    trainingRecordStatus,
    searchText,
    organizationId,
  }: {
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Course types
     */
    courseType?: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Regulatory Agencies Names
     */
    regulatoryAgencies?: Array<string>;
    /**
     * Date range start for start date of the training event
     */
    startDateRangeFrom?: string;
    /**
     * Date range end for start date of the training event
     */
    startDateRangeTo?: string;
    /**
     * Start date of the training event
     */
    startDateAfter?: string;
    /**
     * End date of the training event
     */
    endDateBefore?: string;
    /**
     * Etca status
     */
    etcaStatus?: Array<'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'>;
    /**
     * TSA status
     */
    tsaStatus?: Array<
      'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
    >;
    /**
     * Schedule status
     */
    scheduleStatus?: Array<'Available' | 'NotAvailable'>;
    /**
     * Training records status
     */
    trainingRecordStatus?: Array<RecordStatus>;
    /**
     * Search term for additional filtering on any of reservation number, pilot name and course name
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<ReservationsMetadata> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/meta',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        location: location,
        courseType: courseType,
        platform: platform,
        regulatoryAgencies: regulatoryAgencies,
        startDateRangeFrom: startDateRangeFrom,
        startDateRangeTo: startDateRangeTo,
        startDateAfter: startDateAfter,
        endDateBefore: endDateBefore,
        etcaStatus: etcaStatus,
        tsaStatus: tsaStatus,
        scheduleStatus: scheduleStatus,
        trainingRecordStatus: trainingRecordStatus,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ReservationFilters OK
   * @throws ApiError
   */
  public static getReservationFilters({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<ReservationFilters> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/filters',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static exportReservationsData({
    status = 'all',
    location,
    courseType,
    platform,
    regulatoryAgencies,
    startDateRangeFrom,
    startDateRangeTo,
    startDateAfter,
    endDateBefore,
    etcaStatus,
    tsaStatus,
    scheduleStatus,
    trainingRecordStatus,
    searchText,
    organizationId,
  }: {
    /**
     * Status of the reservations
     */
    status?:
      | 'all'
      | 'provisional'
      | 'completed'
      | 'confirmed'
      | 'inProgress'
      | 'recentlyCompleted'
      | 'upcoming';
    /**
     * Training locations
     */
    location?: Array<string>;
    /**
     * Course types
     */
    courseType?: Array<'Initial' | 'Recurrent' | 'Upgrade' | 'Other'>;
    /**
     * Aircraft names
     */
    platform?: Array<string>;
    /**
     * Regulatory Agencies Names
     */
    regulatoryAgencies?: Array<string>;
    /**
     * Date range start for start date of the training event
     */
    startDateRangeFrom?: string;
    /**
     * Date range end for start date of the training event
     */
    startDateRangeTo?: string;
    /**
     * Start date of the training event
     */
    startDateAfter?: string;
    /**
     * End date of the training event
     */
    endDateBefore?: string;
    /**
     * Etca status
     */
    etcaStatus?: Array<'Sent' | 'NotSent' | 'NotApplicable' | 'Optional'>;
    /**
     * TSA status
     */
    tsaStatus?: Array<
      'Approved' | 'WaitPeriodApproved' | 'Other' | 'NotApplicable'
    >;
    /**
     * Schedule status
     */
    scheduleStatus?: Array<'Available' | 'NotAvailable'>;
    /**
     * Training records status
     */
    trainingRecordStatus?: Array<RecordStatus>;
    /**
     * Search term for additional filtering on any of reservation number, pilot name and course name
     */
    searchText?: string;
    organizationId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/export',
      headers: {
        'Organization-Id': organizationId,
      },
      query: {
        status: status,
        location: location,
        courseType: courseType,
        platform: platform,
        regulatoryAgencies: regulatoryAgencies,
        startDateRangeFrom: startDateRangeFrom,
        startDateRangeTo: startDateRangeTo,
        startDateAfter: startDateAfter,
        endDateBefore: endDateBefore,
        etcaStatus: etcaStatus,
        tsaStatus: tsaStatus,
        scheduleStatus: scheduleStatus,
        trainingRecordStatus: trainingRecordStatus,
        searchText: searchText,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ReservationAlertsResponse OK
   * @throws ApiError
   */
  public static getAlertsCountByCardType({
    organizationId,
  }: {
    organizationId?: string;
  }): CancelablePromise<ReservationAlertsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/alerts',
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
  /**
   * @returns ReservationResponse OK
   * @throws ApiError
   */
  public static getAlertDetailsByType({
    alertType,
    organizationId,
  }: {
    alertType: string;
    organizationId?: string;
  }): CancelablePromise<Array<ReservationResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/reservations/alerts/{alertType}',
      path: {
        alertType: alertType,
      },
      headers: {
        'Organization-Id': organizationId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
  }
}
