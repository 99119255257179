import './InvoicePaymentsContactInfo.css';
import { selectInvoicesContactInfo } from '../../api/selectors';
import { useUserInfo } from '@/contexts/userInfo';
import useCustomerCareContactsQuery from '@/features/support-menu/api/useCustomerCareContactsQuery';

export function InvoicePaymentsContactInfo(): JSX.Element {
  const { selectedOrganization } = useUserInfo();
  const { data } = useCustomerCareContactsQuery(selectedOrganization?.name);
  const accountExecutive = selectInvoicesContactInfo(data?.accountExecutive);
  return (
    <section className="dialog-detail">
      <h3>Contact Information</h3>
      <p>
        If you have additional questions about this transaction, please contact:
      </p>
      {accountExecutive && (
        <>
          <h4>
            {accountExecutive?.name ? (
              <>
                <b>{accountExecutive.name}</b> | Account Executive
              </>
            ) : (
              <>Account Executive</>
            )}
          </h4>
          <p>{accountExecutive.phone}</p>
          <p>{accountExecutive.email}</p>
        </>
      )}
      {!accountExecutive && <p>No contact information available</p>}
    </section>
  );
}
