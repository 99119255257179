import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  RecordStatus,
  ReservationManagementService,
  ReservationsMetadata,
} from '@/open-api';
import { format } from 'date-fns';
import { DefinedReservationFilters } from '../components/filter-modal/useFilterModalReducer';

export function useReservationsMetadataQuery(
  filters?: DefinedReservationFilters
): UseQueryResult<ReservationsMetadata, Error> {
  const {
    location,
    platform,
    courseType,
    etcaStatus,
    tsaStatus,
    scheduleStatus,
    trainingRecord,
    dateRange,
    authorities,
  } = filters || {};

  return useQuery<ReservationsMetadata, Error>({
    queryKey: ['reservationsCount', filters],
    queryFn: () =>
      ReservationManagementService.getReservationsMetadata({
        location: location,
        courseType: courseType,
        platform: platform,
        regulatoryAgencies: authorities,

        startDateRangeFrom:
          dateRange && dateRange[0]
            ? format(dateRange[0], 'yyyy-MM-dd')
            : undefined,
        startDateRangeTo:
          dateRange && dateRange[1]
            ? format(dateRange[1], 'yyyy-MM-dd')
            : undefined,
        etcaStatus,
        tsaStatus,
        scheduleStatus: scheduleStatus,
        trainingRecordStatus:
          trainingRecord === undefined || trainingRecord.length === 0
            ? [
                RecordStatus.AVAILABLE,
                RecordStatus.HARD_COPY_ONLY,
                RecordStatus.NOT_APPLICABLE,
                RecordStatus.PENDING,
              ]
            : trainingRecord,
      }),
  });
}
