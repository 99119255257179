import { useNavigate } from 'react-router-dom';

import {
  SubmitHandler,
  Controller,
  useForm,
  FieldValues,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button, TextInput } from '@cae/cobalt-react';
import { RecordManagementService, ApiError } from '@/open-api';
import * as React from 'react';
import { toastMessages } from '@/shared/helpers/toastMessages';

const validateTitle = z
  .string()
  .min(1, 'Title is required')
  .min(3, 'Min. 3 characters')
  .max(30, 'Max. 30 characters');

const validateDetails = z
  .string()
  .min(1, 'Details are required')
  .min(3, 'Min. 3 characters')
  .max(1000, 'Max. 1000 characters');

const FormSchema = z.object({
  title: validateTitle,
  details: validateDetails,
});

type Props = {
  readonly recordId: string;
  readonly handleCloseRecord: (e: React.SyntheticEvent) => void;
  readonly setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

export function RecordRequestForm({
  recordId = '',
  handleCloseRecord,
  setHasUnsavedChanges,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FieldValues> = async data => {
    const { details: text, title } = data;
    try {
      await RecordManagementService.updateRecordRequestEmail({
        reservationId: recordId,
        requestBody: {
          title,
          text,
        },
      });
      toastMessages.success('Request submitted successfully!');
      navigate(`/records`);
    } catch (error) {
      if (error instanceof ApiError) {
        toastMessages.error(
          'Your request has not yet been sent. Try again, or contact the administrators.'
        );
        navigate(`/records`);
      } else {
        toastMessages.error(
          'Oops, an error occurred. Try again, or contact the administrators.'
        );
        navigate(`/records`);
      }
    }
  };

  const {
    control,
    formState: { errors, touchedFields, isSubmitting, isValid },
    handleSubmit,
    register,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      recordId,
      title: '',
      details: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(FormSchema),
  });

  const { title, details } = getValues();
  React.useEffect(() => {
    if (title || details) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [title, details, setHasUnsavedChanges]);

  const handleCancelClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    if (title || details) {
      handleCloseRecord(e);
    } else {
      navigate(`/records/${recordId}`);
    }
  };

  return (
    <form
      action="."
      method="post"
      className="dialog-details__fragment"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <h3>Request summary</h3>
      <p>
        Please enter a summary title of the request and then enter as many
        details as you can that will allow us to process it.
      </p>
      <fieldset>
        <input type="hidden" {...register('reservationId')} />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting,
              }}
              label="Title *"
              meta={{
                error: (errors.title?.message as string) || '',
                touched: !!touchedFields.title,
              }}
              maxChars={30}
            />
          )}
        />
        <Controller
          control={control}
          name="details"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting,
              }}
              label="Description and details *"
              meta={{
                error: (errors.details?.message as string) || '',
                touched: !!touchedFields.details,
              }}
              multiline
              maxChars={1000}
            />
          )}
        />
      </fieldset>

      <div className="form-actions">
        <Button type="button" variant="secondary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting || !isValid}
          pending={isSubmitting}
        >
          Send request
        </Button>
      </div>
    </form>
  );
}
