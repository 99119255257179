import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {
  PagedListOfReservationResponseDto,
  ReservationManagementService,
} from '@/open-api';
import useDebounce from '@/shared/hooks/useDebounce';
import { format } from 'date-fns';
import { DefinedReservationFilters } from '../components/filter-modal/useFilterModalReducer';

export enum Icon {
  Alert = 'alert',
  Error = 'error',
}

export type ReservationType =
  | 'all'
  | 'provisional'
  | 'completed'
  | 'confirmed'
  | 'inProgress'
  | 'upcoming'
  | 'recentlyCompleted';

export type ReservationSortingOptions =
  | '+schedulerReservationId'
  | '+status'
  | '+clientName'
  | '+aircraftName'
  | '+courseName'
  | '+startDate'
  | '+endDate'
  | '+location'
  | '-schedulerReservationId'
  | '-status'
  | '-clientName'
  | '-aircraftName'
  | '-courseName'
  | '-startDate'
  | '-endDate'
  | '-location';

export function useGetReservationsTableDataQuery(
  reservationType: ReservationType,
  pageIndex: number,
  pageSize: number,
  filters: DefinedReservationFilters,
  searchText: string,
  sorting?: Array<ReservationSortingOptions>
): UseQueryResult<PagedListOfReservationResponseDto, unknown> {
  const {
    location,
    platform,
    courseType,
    etcaStatus,
    tsaStatus,
    scheduleStatus,
    trainingRecord,
    dateRange,
    authorities,
  } = filters;

  const debouncedValue = useDebounce(searchText, 500);

  return useQuery({
    queryKey: [
      reservationType,
      pageIndex,
      pageSize,
      filters,
      debouncedValue,
      sorting,
    ],
    queryFn: () =>
      ReservationManagementService.getReservations({
        status: [reservationType],
        pageIndex,
        pageSize,
        location: location,
        regulatoryAgencies: authorities,
        courseType: courseType,
        platform: platform,
        startDateRangeFrom: dateRange[0]
          ? format(dateRange[0], 'yyyy-MM-dd')
          : undefined,
        startDateRangeTo: dateRange[1]
          ? format(dateRange[1], 'yyyy-MM-dd')
          : undefined,
        etcaStatus,
        tsaStatus,
        scheduleStatus: scheduleStatus,
        trainingRecordStatus: trainingRecord,
        searchText: debouncedValue,
        sort: sorting,
      }),
  });
}
