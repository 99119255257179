import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, Modal } from '@cae/cobalt-react';

import { useViewportSize } from '@/shared/hooks/useViewportSize';

type ModalActionsProps = {
  readonly handleCancel: React.MouseEventHandler;
  readonly handleConfirm: React.MouseEventHandler;
};

function DiscardModalActions({
  handleCancel,
  handleConfirm,
}: ModalActionsProps): JSX.Element {
  return (
    <>
      <Button
        type="button"
        variant="secondary"
        className="primary-btn-color"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Discard
      </Button>
    </>
  );
}

export function ReservationDetailsPage({
  onClose,
}: { onClose?: (e: React.SyntheticEvent) => void } = {}): JSX.Element | null {
  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { reservationId = '' } = useParams();
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = React.useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);

  const currentUrl = window.location.href;
  const targetUrl = currentUrl.includes('/home')
    ? `/home/${reservationId}`
    : `/reservations/${reservationId}`;

  const handleCloseReservation = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (window.location.href.includes('/request')) {
      if (hasUnsavedChanges) {
        setIsDiscardDialogOpen(true);
      } else {
        navigate(targetUrl);
      }
    } else {
      navigate('/reservations');
    }
  };

  const handleCancelDiscard = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
  };

  const handleDiscard: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setIsDiscardDialogOpen(false);
    navigate(targetUrl);
  };

  return reservationId ? (
    <>
      {' '}
      <Modal
        animate
        className="reservation-details--dialog"
        dismissible
        onCancel={onClose ?? handleCloseReservation}
        open
        placement={width < 480 ? 'bottom' : 'right'}
        data-testid="reservationDetailsPanel"
      >
        <Outlet context={{ handleCloseReservation, setHasUnsavedChanges }} />
      </Modal>
      <Dialog
        modal
        className="reservation-request-page"
        dismissible
        open={isDiscardDialogOpen}
        header={<h2>Discard change request?</h2>}
        onCancel={handleCancelDiscard}
        onConfirm={handleDiscard}
        actions={DiscardModalActions}
        placement="center"
        size="sm"
        data-testid="reservationDetailsChangeRequestCancelDialog"
      >
        <p>You will lose all information entered for this request.</p>
      </Dialog>
    </>
  ) : null;
}
