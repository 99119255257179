import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, TextInput } from '@cae/cobalt-react';

import type { FieldValues, SubmitHandler } from 'react-hook-form';

import type { PostResponseStatus } from '@/shared/types';

import './ChangeRequestForm.css';

interface LeavePromptActionsProps {
  handleCancel: React.MouseEventHandler<HTMLButtonElement>;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

function LeavePromptActions({
  handleCancel,
  handleConfirm,
}: Readonly<LeavePromptActionsProps>): JSX.Element {
  return (
    <>
      <Button type="button" variant="secondary" onClick={handleCancel}>
        Wait
      </Button>
      <Button type="button" variant="primary" onClick={handleConfirm}>
        Yes, Discard
      </Button>
    </>
  );
}

const validateTitle = z
  .string()
  .min(1, 'Title is required')
  .min(3, 'Min. 3 characters')
  .max(30, 'Max. 30 characters');
const validateDetails = z
  .string()
  .min(1, 'Details are required')
  .min(3, 'Min. 3 characters')
  .max(1000, 'Max. 1000 characters');

const FormSchema = z.object({
  title: validateTitle,
  details: validateDetails,
});

interface Props {
  initialValues: {
    title: string;
    details: string;
  };
  onCancel?: (e: React.SyntheticEvent<HTMLDialogElement | Element>) => void;
  onSubmit: SubmitHandler<FieldValues>;
  promptOnLeave?: boolean;
  status: PostResponseStatus;
  readonly setHasUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ChangeRequestForm({
  initialValues = { title: '', details: '' },
  onCancel,
  onSubmit,
  promptOnLeave = false,
  status = { type: 'idle' },
  setHasUnsavedChanges,
}: Readonly<Props>): JSX.Element {
  const [leaveConfirmed, setLeaveConfirmed] = React.useState<
    boolean | undefined
  >();

  const {
    control,
    formState: { errors, touchedFields, isDirty, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: zodResolver(FormSchema),
  });

  React.useEffect(() => {
    if (isDirty && setHasUnsavedChanges) {
      setHasUnsavedChanges(true);
    } else if (setHasUnsavedChanges) {
      setHasUnsavedChanges(false);
    }
  }, [isDirty, setHasUnsavedChanges]);

  const handleLeave = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (promptOnLeave && isDirty && leaveConfirmed === undefined) {
      setLeaveConfirmed(false);
    } else {
      typeof onCancel === 'function' && onCancel(e);
    }
  };

  const handleContinue = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    setLeaveConfirmed(undefined);
  };

  return (
    <form
      action="."
      method="post"
      className="dialog-details__fragment"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      noValidate
    >
      <h3>Request summary</h3>
      <p>
        Please enter a summary title of the request and then enter as many
        details as you can that will allow us to process it.
      </p>
      <fieldset>
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <TextInput
              className="title"
              field={{
                ...field,

                size: 30,
                maxLength: 30,
                disabled: isSubmitting || status.type === 'success',
                placeholder: 'Type request title',
              }}
              label="Title *"
              maxChars={30}
              meta={{
                error: (errors.title?.message as string) || '',
                touched: !!touchedFields.title,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="details"
          render={({ field }) => (
            <TextInput
              field={{
                ...field,
                disabled: isSubmitting || status.type === 'success',
                maxLength: 1000,
                placeholder: 'Type request description and details',
              }}
              label="Description and details *"
              maxChars={1000}
              meta={{
                error: (errors.details?.message as string) || '',
                touched: !!touchedFields.details,
              }}
              multiline
            />
          )}
        />
      </fieldset>

      <div className="form-actions">
        <Button type="button" variant="secondary" onClick={handleLeave}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={status.type === 'success' || !isValid || isSubmitting}
          pending={isSubmitting}
        >
          Send request
        </Button>
      </div>

      {promptOnLeave && leaveConfirmed === false ? (
        <Modal
          actions={LeavePromptActions}
          className="cancel-prompt"
          dismissible={false}
          open
          header={<h2>Discard change request?</h2>}
          onCancel={handleContinue}
          onConfirm={handleLeave}
          placement="center"
          size="sm"
        >
          <p>You will lose all information entered for this request.</p>
        </Modal>
      ) : null}
    </form>
  );
}
