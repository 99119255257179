import { useCallback, useEffect, useState } from 'react';
import './SFConnectLoader.css';
import { ProgressLine } from '@cae/cobalt-react';
import { usePostSFConnectQuery } from '../../api/usePostSFConnectQuery';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../../assets/images/cae-logo.svg';
import { useGetCurrentOrganization } from '@/features/frame/api/useGetCurrentOrganization';
import { useGetUserDiscrepancies } from '@/features/users/api/useGetUserDiscrepancies';

function SFConnectLoader(): JSX.Element {
  const [progress, setProgress] = useState(70);
  const navigate = useNavigate();
  const { handleOrgUpdate } = useGetCurrentOrganization();
  const sfAccount = sessionStorage.getItem('sfaccountid') ?? '';
  const sfContact = sessionStorage.getItem('sfmastercontactid') ?? '';
  const {
    data,
    status: queryStatus,
    error,
  } = usePostSFConnectQuery({
    userSalesforceContact: sfContact,
    customerSalesforceId: sfAccount,
  });

  const { data: userDiscrepancies, status: discrepanciesStatus } =
    useGetUserDiscrepancies(data?.id ?? '');

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleResponse = useCallback((): void => {
    const handleError = (): void => {
      if (error?.toString()?.includes('Forbidden')) {
        navigate('/sf-connect/unauthorized');
      } else {
        navigate('/sf-connect/create/error');
      }
    };

    const handleSuccess = (): void => {
      if (data?.isEmailConflictInSF) {
        navigate('/sf-connect/create/multiple-accounts', {
          state: { userData: data, userDiscrepanciesData: null },
        });
      } else if (!data?.isOrganizationExists) {
        navigate('/sf-connect/create/organization', {
          state: { userData: data, sfAccount, sfContact },
        });
      } else if (
        data.organizationId &&
        (!data.isUserIdValid ||
          userDiscrepancies?.additionalAttrs?.userDiscrepancies)
      ) {
        navigate('/sf-connect/create/multiple-accounts', {
          state: { userData: data, userDiscrepanciesData: userDiscrepancies },
        });
      } else if (
        data.id &&
        data.isUserEmailValid &&
        data.isAlreadyExistsInOrg
      ) {
        handleOrgUpdate(data.organizationId as string, data);
      } else if (!data.isAlreadyExistsInOrg) {
        handleOrgUpdate(data.organizationId as string, data);
      }
    };

    if (
      queryStatus === 'error' ||
      error ||
      (data?.id ? discrepanciesStatus === 'error' : false)
    ) {
      handleError();
      return;
    }

    if (
      queryStatus === 'success' &&
      data &&
      (data.id ? discrepanciesStatus === 'success' : true)
    ) {
      handleSuccess();
    }
  }, [
    queryStatus,
    data,
    error,
    discrepanciesStatus,
    userDiscrepancies,
    navigate,
    sfAccount,
    sfContact,
    handleOrgUpdate,
  ]);

  useEffect(() => {
    handleResponse();
  }, [handleResponse]);

  return (
    <div className="container">
      <img className="sf-connect--logo" src={Logo} alt="cae-logo" />
      <div className="sf-connect--row">
        <div>
          <span
            style={{
              fontFamily: 'var(--font-family-display)',
              fontSize: '48px',
            }}
          >
            CAE{' '}
          </span>
          <span
            style={{
              fontFamily: 'var(--font-family-display)',
              fontWeight: 'bold',
              fontSize: '48px',
            }}
          >
            Connect
          </span>
        </div>
        <ProgressLine value={progress} />
        <p>Redirection to the app...</p>
      </div>
      <p className="sf-connect--footer">
        Copyright ©2024, CAE Inc. All rights reserved.
      </p>
    </div>
  );
}

export default SFConnectLoader;
