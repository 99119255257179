import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { MsalProvider } from '@azure/msal-react';
import {
  AuthError,
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import Logger from './libs/logging/Logger';
import './i18n';
import configureOpenAPI from './open-api/custom/configureOpenAPI';

import 'react-toastify/dist/ReactToastify.css';
import '@cae/cobalt-react/style.css';

import { msalConfigB2C, msalConfigB2E } from './configs/authConfig';
import configureInterceptor from './libs/logging/configureInteceptor';
import { DOMAIN_SCRIPT_KEY } from './env';

const { href } = document.location;

if (
  href === `${window.location.origin}/sso` ||
  (href.includes('sfmastercontactid') && href.includes('sfaccountid'))
) {
  const urlParams = new URLSearchParams(window.location.search);
  const sfmastercontactid = urlParams.get('sfmastercontactid');
  const sfaccountid = urlParams.get('sfaccountid');

  if (sfmastercontactid && sfaccountid) {
    sessionStorage.setItem('sfmastercontactid', sfmastercontactid);
    sessionStorage.setItem('sfaccountid', sfaccountid);
  }
  sessionStorage.setItem('auth-type', 'b2e');
} else if (href === `${window.location.origin}`) {
  sessionStorage.setItem('auth-type', 'b2c');
}

configureInterceptor();

const isB2EUser = sessionStorage.getItem('auth-type') === 'b2e';
const config = isB2EUser ? msalConfigB2E : msalConfigB2C;

const msalInstance = new PublicClientApplication(config);
const injectScriptWithKey = (): void => {
  const script = document.createElement('script');
  script.setAttribute('data-domain-script', DOMAIN_SCRIPT_KEY);
  script.type = 'text/javascript';
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  document.head.appendChild(script);
};

msalInstance.initialize().then(async () => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  configureOpenAPI();

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(event => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      (event?.payload as AuthenticationResult)?.account
    ) {
      const { account } = event.payload as AuthenticationResult;
      msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.SSO_SILENT_FAILURE) {
      let errorMessage = 'MSAL library error. SSO Silent failure.';

      if (event.error instanceof AuthError) {
        errorMessage = event.error.errorMessage;
      }

      Logger.log({
        type: 'code',
        level: 'error',
        message: errorMessage,
      });
    }
  });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCHDARKLY_SDK_KEY,
  });

  async function enableMocking(): Promise<
    ServiceWorkerRegistration | undefined
  > {
    if (import.meta.env.MODE !== 'mock') {
      return;
    }

    const { worker } = await import('./mocks/setupWorker');

    return worker.start();
  }

  enableMocking().then(() => {
    injectScriptWithKey();
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <React.StrictMode>
        <LDProvider>
          <MsalProvider instance={msalInstance}>
            <ToastContainer
              containerId="general"
              hideProgressBar
              style={{
                position: 'absolute',
                marginTop: '4rem',
                zIndex: 9999,
              }}
              theme="colored"
            />
            <App />
          </MsalProvider>
        </LDProvider>
      </React.StrictMode>
    );
  });
});
export { msalInstance };
