import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardHeader } from '@cae/cobalt-react';
import './RecordPublishedAlertDetails.css';

interface Alert {
  recordId: string;
  courseName: string;
  clientName: string;
  endDate: string;
}

const RecordPublishedAlertDetails: React.FC = () => {
  const location = useLocation();
  const alertData =
    (location.state as { alertData?: Alert[] })?.alertData || [];

  const getDescription = (numberOfAlerts: number): string => {
    return `You have ${numberOfAlerts} digital records published in the last 14 days.`;
  };

  return (
    <aside className="records--alert-details">
      {alertData.length === 0 ? (
        <p>No records available.</p>
      ) : (
        <>
          <h3>List of Records</h3>
          <p>{getDescription(alertData.length)}</p>
          <div className="scroll-container record-alerts--details-container">
            {alertData.map((alert: Alert) => (
              <Link to={`/records/${alert.recordId}`} key={alert.recordId}>
                <Card key={alert.recordId}>
                  <CardHeader>
                    <hgroup>
                      <h5>{alert.courseName}</h5>
                      <h4>{alert.clientName}</h4>
                    </hgroup>
                    <svg height="12" width="12" className="alert-danger-circle">
                      <circle cx="6" cy="6" r="6" fill="var(--danger-color)" />
                    </svg>
                  </CardHeader>
                  <p>
                    End Date: {new Date(alert.endDate).toLocaleDateString()}
                  </p>
                </Card>
              </Link>
            ))}
          </div>
        </>
      )}
    </aside>
  );
};

RecordPublishedAlertDetails.displayName = 'RecordPublishedAlertDetails';

export default RecordPublishedAlertDetails;
